#tz-menu-wrapper {
  #plazart-mainnav {
    margin: 0;
    border: none;
    .navbar-collapse {
      padding: 0;
    }
  }
  border-top: 1px solid @plazart-border-color;
  border-bottom: 1px solid @plazart-border-color;
}
#tz-header-wrapper {
  padding: 20px 0;
}

.tz-module {
  .nav > {
    li > {
      a {
        display: block;
        padding: 5px 10px;
      }
    }
  }
}

.form-group {
  margin-bottom: 20px;
}

#login-form ul.unstyled {
  margin-top: 20px;
  padding-left: 20px;
}

.module-title {
  margin-bottom: 20px;
  font-size: 22px;
}

.framework-logo {
  padding: 10px 0 30px;
}
